const dataFaqs = [
    {
        id: 1,
        title: "What's the difference between an AI autonomous agent and a chatbot?",
        text: "An AI autonomous agent can perform tasks, make decisions, and interact without human intervention, based on its training and programming. A chatbot, on the other hand, is designed specifically for conversational interactions and may not have the broader capabilities or autonomy that AI agents possess.",
        show: ''
    },
    {
        id: 2,
        title: "I'm a solopreneur. Can I benefit from AI automation?",
        text: "Absolutely! AI automation can streamline operations, handle repetitive tasks, and provide insights, irrespective of the size of your business. Nexacorp can tailor AI solutions to fit your unique needs, ensuring efficiency and growth. On a budget? AI automation helps you save money. It doesn't have to be expensive to get started!",
        show: ''
    },
    {
        id: 3,
        title: "Do you only cater to large corporations or can small businesses also benefit from Nexacorp's services?",
        text: "Nexacorp caters to businesses of all sizes. Our solutions are scalable, ensuring both startups and large enterprises can harness the power of AI effectively.",
        show: ''
    },
    {
        id: 4,
        title: "How customizable are the AI solutions Nexacorp offers?",
        text: "Highly customizable. We believe every business is unique, and our AI solutions are tailored to match your specific requirements, goals, and challenges.",
        show: ''
    },
    {
        id: 5,
        title: "Do I have to use my own OpenAI API key?",
        text: "While you can use your own OpenAI API key, Nexacorp also offers alternative solutions— including open-source and private / bespoke solutions—and we can guide you on the best approach based on your needs.",
        show: ''
    },
    {
        id: 6,
        title: "How long does it take to integrate and implement an AI solution from Nexacorp?",
        text: "Implementation time varies based on the complexity and customization of the solution. However, we prioritize a smooth and swift integration process to ensure minimal disruption to your operations.",
        show: ''
    },
    {
        id: 7,
        title: "Do you support [open source LLM list / open-source AI tool / service]?",
        text: "Yes, Nexacorp stays updated with major open-source tools and platforms. If you have specific tools in mind, please share the details, and we'll confirm compatibility.",
        show: ''
    },
    {
        id: 8,
        title: "How is billing handled for AI services?",
        text: "Our billing structure is transparent and based on the services you opt for. We offer both package-based pricing and custom solutions, ensuring flexibility for your budget.",
        show: ''
    }
]

export default dataFaqs;
