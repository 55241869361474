
import img1 from '../images/layouts/project-01.png'
import img2 from '../images/layouts/project-02.png'
import img3 from '../images/layouts/project-03.png'
import img4 from '../images/layouts/project-04.png'
import img5 from '../images/layouts/project-05.png'

const dataProject = [

    {
        id: 1,
        img: img1,
        title: '3D Digital Artwork'
    },
    {
        id: 2,
        img: img2,
        title: 'King Of Pirates'
    },
    {
        id: 3,
        img: img3,
        title: 'Pomeranian Doge'
    },
    {
        id: 4,
        img: img4,
        title: 'Nintendo Switch'
    },
    {
        id: 5,
        img: img5,
        title: 'The Strange Art'
    },
    {
        id: 6,
        img: img1,
        title: '3D Digital Artwork'
    },
    {
        id: 7,
        img: img2,
        title: 'King Of Pirates'
    },
    {
        id: 8,
        img: img3,
        title: 'Pomeranian Doge'
    },
    {
        id: 9,
        img: img4,
        title: 'Nintendo Switch'
    },


    

]

export default dataProject;