
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Beyond',
        desc: 'Glimpse into the next-gen AI era. Be prepared. '
    },
    {
        id: 2,
        img: img2,
        title: 'Autonomy',
        desc: "AI that collaborates, innovates, and leads."
    },
    {
        id: 3,
        img: img3,
        title: 'Evolution',
        desc: "AI doesn't just assist; it dominates."
    },
    {
        id: 4,
        img: img4,
        title: 'Eclipse',
        desc: 'Human limitations are left behind.'
    },

]

export default dataBox;