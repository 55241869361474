

const dataRoadMap = [

    {
        id: 1,
        time: '2001',
        title: 'SmarterChild',
        desc: 'AIM / MSN / Yahoo chatbot SmarterChild was released by ActiveBuddy to demonstrate the power of “conversational computing”.  ActiveBuddy marketed Automated Service Agents—delivering immediate answers to customer service inquiries—to large corporations like Comcast, Cingular, TimeWarner Cable, etc. ',
        class: ''
    },
    {
        id: 2,
        time: '2002',
        title: "No, You're Human.",
        desc: 'The founder of Nexacorp spent hours socializing with the early chatbot SmarterChild, arguing often about which one of them was human. SmarterChild amassed over 30 million "buddies."',
        class: 'right'
    },

    {
        id: 3,
        time: '2020',
        title: 'AI Playground',
        desc: "OpenAI releases GPT-3, an ancestor of ChatGPT. Nexacorp's founder tirelessly questions GPT-3 about 'the simulation' (it said OpenAI created it) and the Mandela Effect (GPT-3 also thought cars say 'objects in mirror may be closer than they appear.')",
        class: ''
    },

    {
        id: 4,
        time: '2022',
        title: 'AI Ascendance',
        desc: 'OpenAI releases ChatGPT (GPT-3.5) which grows 9,900% up to 100 million users within two months. ChatGPT got 1.8 billion visits in April 2023.',
        class: 'right'
    },
    {
        id: 5,
        time: '2023',
        title: 'New World Order',
        desc: "GPT-4 passes the Turing test, shows 'sparks of AGI', scores in the top 1% of humans for creativity, passes the Bar exam, medical doctor exam [...] Autonomous agents with access to internet search, coding and execution skills, begin to evolve. Multi-agent systems evolve to allow AI agents to collaborate. Nexacorp Technologies assists in developing these frameworks.",
        class: ''
    },



]

export default dataRoadMap;