import React , {useState} from 'react';

import img from '../../assets/images/layouts/create.png'

//import Button from '../components/button/Button';

function Create(props) {

    
    const [dataBlock] = useState(
        {
            heading: 'Evolve With AI & Nexacorp',
            desc: 'Book a call for insights & strategies',
            
        }
    )
    return (
        <section className="create">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="create__main">
                                <div className="content">
                                    <h4 className="heading">{dataBlock.heading}</h4>
                                    <p>{dataBlock.desc}</p>
                                    <a href="https://cal.com/nexacorp/discovery" className="action-btn" target="_blank" rel="noopener noreferrer">
    <span>Take The First Step</span>
</a>
                                    
                                
                                </div>
                                <img src={img} alt="Nexacorp" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Create;