import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/layouts/banner.png'
import img2 from '../../assets/images/icon/icon-01.png'
import img3 from '../../assets/images/layouts/avt-01.png'


function Banner(props) {
    return (
        <section className="banner">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text">
                                    <h2 className="heading">Leaders Who Are<br />
                                        <span className="s1 arlo_tm_animation_text_word">More Than Human</span> <br />
                                        </h2>
                                    <p className="desc">Autonomous AI leaders who devise and execute intelligent strategies in real-time. Dynamic collaborators who can effectively transcend—or replace—entire teams. </p>
                                    <p className="desc">Redefine what’s possible with efficiency, innovation, and strategic depth on an unparalleled scale.</p>
                                    <a href="https://cal.com/nexacorp/discovery" className="action-btn" target="_blank" rel="noopener noreferrer">
    <span>Embrace The Future</span>
</a>

                                </div>

                                <div className="pay">
                                    <h6>We're from the future.</h6>

                                    <div className="list">
                                        <p>Traditional currency welcome. We also accept:</p>

                                        <ul>
                                            <li><Link to="#"><span className="icon-logo-01"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-02"></span></Link></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">

                            <div className="banner__right">
                                <div className="image">
                                    <img src={img1} alt="cyfonii" />
                                </div>

                                <div className="price">
                                    <div className="icon">
                                        <img src={img2} alt="cyfonii" />
                                    </div>
                                    <div className="content">
                                        <p>Current State:</p>
                                        <h5>Observing...</h5>
                                    </div>
                                </div>

                                <div className="owner">
                                    <div className="image">
                                        <img src={img3} alt="cyfonii" />
                                    </div>
                                    <div className="content">
                                        <h5>Lila Vortex</h5>
                                        <p>@LilaVortex</p>
                                        <p>CTO//AI, Nexacorp Technologies</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;