import React from 'react';

import Footer from '../components/footer/Footer';



// import img from '../assets/images/layouts/touch.png'

function Contact(props) {
    return (
        <div className='wrapper'>

            

            <section className="touch">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h6 className="sub-heading"><span>Contact Nexacorp</span></h6>
                                <h3 className="heading">Let’s Collaborate  <br />
                                On The Future
                                    </h3>
                            </div>
                           <div>
                            <iframe 
                                    id="JotFormIFrame-232645527905158"
                                    title=" Contact Form"
                                    allowtransparency="true"
                                    allowfullscreen="true"
                                    allow="geolocation; microphone; camera"
                                    src="https://form.jotform.com/232645527905158"
                                    frameborder="0"
                                    style={{minWidth: "100%", maxWidth: "100%", height: "539px", border: "none"}}
                                    scrolling="no">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          
           
            <Footer />


            
        </div>
    );
}

export default Contact;