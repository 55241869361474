import React, { useState } from 'react';

import Partner from '../partner/Partner';

import img from '../../assets/images/layouts/about-06.png'

//import Button from '../components/button/Button';

function About2(props) {

    const [dataBlock] = useState(
        {
            subheading: 'About Nexacorp',
            heading: 'At the Nexus of Human Creativity and Artificial Intelligence',
            sections: [
                {
                    header: 'Human-in-the-Loop: A Partnership of Equals',
                    desc: 'The brilliance of AI is undeniable, but so is the ingenuity of the human spirit. Our \'Human-in-the-Loop\' approach ensures that AI doesn\'t just work for us, but alongside us. By intertwining human intuition with AI\'s computational prowess, we generate outcomes that are greater than the sum of their parts.'
                },
                {
                    header: 'Autonomous AI Agents & Multi-Agent Systems',
                    desc: 'The digital world is vast, and its challenges are multifaceted. Our specialty lies in designing and deploying autonomous AI agents, each tailored to tackle specific tasks with precision. But when the stakes are high, these agents come together, forming robust multi-agent systems that collaboratively navigate complexities, ensuring optimal solutions every time.'
                },
                {
                    header: 'Crafting Your Ideal AI Ecosystem',
                    desc: 'The market is brimming with AI tools and solutions, each promising revolutionary results. At Nexacorp, we cut through the noise. Our seasoned experts work closely with clients, understanding their unique needs and objectives. We then design a bespoke AI stack, integrating market-leading tools with our proprietary solutions, ensuring seamless, efficient, and impactful automations.'
                },
                {
                    header: 'Utilizing Our Own Innovations',
                    desc: 'We don\'t just preach AI excellence; we practice it. Nexacorp\'s operations are a testament to our capabilities. With our processes powered and optimized by our very own AI solutions, we are a living showcase of what\'s achievable when human vision meets AI innovation.'
                },
                {
                    header: 'Join the Revolution',
                    desc: 'The AI renaissance is upon us, and Nexacorp is at its forefront. Whether you\'re looking to optimize existing processes, explore uncharted AI territories, or completely revolutionize your operations, we\'re your partners in this transformative journey. Let\'s craft the future, together.'
                }
            ]
        }
    )

    return (
        <section className="about s2">
            <div className="shape"></div>
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div className="block-text center pd-0">
                            <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                            <h3 className="heading pd">{dataBlock.heading}</h3>
                        </div>

                        <div className="about__main" data-aos="fade-up" data-aos-duration="3000">
                            <img src={img} alt="Nexacorp" />
                            {
                                dataBlock.sections.map((section, idx) => (
                                    <div key={idx}>
                                        <h5>{section.header}</h5>
                                        <p className="mb-17">{section.desc}</p>
                                    </div>
                                ))
                            }
                            <a href="https://cal.com/nexacorp/discovery" className="action-btn" target="_blank" rel="noopener noreferrer">
                                <span>Book a Discovery Call</span>
                            </a>
                        </div>

                        <Partner />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About2;
