import React from 'react';



function Page404(props) {
    return (
        <></>
    );
}

export default Page404;