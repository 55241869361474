import React , {useState} from 'react';



function Speciality(props) {

    const {data} = props;

    const [dataBlock] = useState(
        
        {
            subheading: 'Unlock The Nexacorp Edge',
            heading: 'Where AI Dominates',
            desc: 'Human era ends >>> Nexacorp begins. Begin AI ascendancy.' ,
            
        }
    )

    return (
        <section className="speciality">
                <div className="shape right"></div>
                <div className="container">
                    <div className="row">
                    <iframe height="600" style={{ marginBottom: '100px' }} src="https://www.youtube.com/embed/S7ebtb4ds0Y?si=z0N-wYAvWXnuL2IA?mute=1&autoplay=0&si=z0N-wYAvWXnuL2IA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        <div className="col-12">                
                     
                            <div className="block-text center">
                                
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading pd">{dataBlock.heading}</h3>
                                    <p className="">{dataBlock.desc}</p>
                            </div>
                        </div>
                        {
                            data.map(idx => (
                                <div key={idx.id} className="col-xl-3 col-md-6">
                                    <div className="speciality-box" >
                                        <div className="icon">
                                            <img src={idx.img} alt="Nexacorp" />
                                        </div>
                                        <h5 className="title">{idx.title}</h5>
                                        <p>{idx.desc}</p>
                                        <h3 className="number">0{idx.id}</h3>
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>
                    

                </div>
            </section>

    );
}

export default Speciality;