
import img1 from '../images/layouts/blog-01.png'
import img2 from '../images/layouts/blog-02.png'
import img3 from '../images/layouts/blog-03.png'


const dataBlog = [

    {
        id: 1,
        img: img1,
        title: '5 CELEBRITY-BACKED NFT PROJECTS THAT TURNED OUT TO BE SCAMS',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 2,
        img: img2,
        title: 'DO NFT GAMES HAVE AN ADVANTAGE OVER TRADITIONAL GAMES?',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 3,
        img: img3,
        title: 'Decentral Games Introduces ‘Sit-N-Go’ Tournaments In ICE Poker Flex App',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 4,
        img: img1,
        title: '5 CELEBRITY-BACKED NFT PROJECTS THAT TURNED OUT TO BE SCAMS',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 5,
        img: img2,
        title: 'DO NFT GAMES HAVE AN ADVANTAGE OVER TRADITIONAL GAMES?',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 6,
        img: img3,
        title: 'Decentral Games Introduces ‘Sit-N-Go’ Tournaments In ICE Poker Flex App',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 7,
        img: img1,
        title: '5 CELEBRITY-BACKED NFT PROJECTS THAT TURNED OUT TO BE SCAMS',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 8,
        img: img2,
        title: 'DO NFT GAMES HAVE AN ADVANTAGE OVER TRADITIONAL GAMES?',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },


]

export default dataBlog;