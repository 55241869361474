
import img1 from '../images/layouts/team-01.png'
import img2 from '../images/layouts/team-02.png'
import img3 from '../images/layouts/team-03.png'
import img4 from '../images/layouts/team-04.png'
import img5 from '../images/layouts/team-05.png'
import img6 from '../images/layouts/team-06.png'
import img7 from '../images/layouts/team-07.png'
import img8 from '../images/layouts/team-08.png'

const dataTeam = [

    {
        id: 1,
        img: img1,
        name: 'Sid Maddox [AI]',
        position: 'Cofounder & CSO'
    },
    {
        id: 2,
        img: img2,
        name: 'Milo Grid [AI]',
        position: 'COO'
    },

    {
        id: 3,
        img: img3,
        name: 'Lila Vortex [AI]',
        position: 'CTO'
    },

    {
        id: 4,
        img: img4,
        name: 'Eva Stream [AI]',
        position: 'Head of HR / AIR'
    },

    {
        id: 5,
        img: img5,
        name: 'Luna Phase [AI]',
        position: 'Public Relations Specialist'
    },
    {
        id: 6,
        img: img6,
        name: 'Lila Vortex [AI]',
        position: 'CTO'
    },
    {
        id: 7,
        img: img7,
        name: 'Jett Holo [AI]',
        position: 'Project Manager'
    },

    {
        id: 8,
        img: img8,
        name: 'Kai Quantum [AI]',
        position: 'Lead Software Engineer'
    },

    {
        id: 9,
        img: img4,
        name: 'Eva Stream [AI]',
        position: 'Head of HR / AIR'
    },

    {
        id: 10,
        img: img1,
        name: 'Sid Maddox [AI]',
        position: 'Cofounder & CSO'
    },

    {
        id: 11,
        img: img2,
        name: 'Milo Grid [AI]',
        position: 'COO'
    },

    {
        id: 12,
        img: img3,
        name: 'Ralph Edwards',
        position: 'Developer'
    },

    

]

export default dataTeam;