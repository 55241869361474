
import img1 from '../images/svg/icon-1.png'
import img2 from '../images/svg/icon-2.png'
import img3 from '../images/svg/icon-3.png'
import img4 from '../images/svg/icon-4.png'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Consult & Strategize',
        desc: "Collaborate with our experts to unveil AI-driven opportunities uniquely suited to your enterprise's needs. We dive deep into your vision and challenges to lay the groundwork for a revolution in operational autonomy."
    },
    {
        id: 2,
        img: img2,
        title: 'Scope & Define',
        desc: "Nexacorp architects a customized AI framework, from singular autonomous systems to comprehensive, self-governing architectures, ensuring scalability and alignment with your objectives. We define the scope of autonomy, from decision-making to operational tasks, tailoring AI's role within your enterprise."
    },
    {
        id: 3,
        img: img3,
        title: 'Refine & Optimize',
        desc: "Post-deployment, our focus shifts to the continuous refinement of your AI's operational efficiency and strategic capability. Nexacorp is dedicated to optimizing your autonomous systems for peak performance, ensuring they not only meet but surpass your expectations."
    },
    {
        id: 4,
        img: img4,
        title: 'Evolve & Lead',
        desc: 'In the dynamic realm of AI, staying ahead means never standing still. Nexacorp ensures your enterprise is perpetually at the forefront, leveraging continuous advancements in AI to maintain your competitive edge. Together, we redefine leadership in an automated world.'
    },


]

export default dataPortfolio;