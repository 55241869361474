const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'About',
        links: '/about',
    },
 
    {
        id: 3,
        name: 'Blog',
        links: 'https://medium.com/@nexacorptech',
        external: true,
    },
    {
        id: 4,
        name: 'Contact',
        links: '/contact',
    },
]

export default menus;
