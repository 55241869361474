import React , {useState} from 'react';

import img1 from '../../assets/images/layouts/about-01.png'
import img2 from '../../assets/images/layouts/about-02.png'
import img3 from '../../assets/images/layouts/about-03.png'
import img4 from '../../assets/images/layouts/about-04.png'
import img5 from '../../assets/images/layouts/about-05.png'
// import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : img1,
            class: 'img1'
        },
        {
            id: 2,
            img : img2,
            class: 'img2'
        },
        {
            id: 3,
            img : img3,
            class: 'img3'
        },
        {
            id: 4,
            img : img4,
            class: 'img4'
        },
        {
            id: 5,
            img : img5,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'About Us',
            heading: 'Why Adapt When You Can Lead?',
            desc1: "Nexacorp AI systems are more than advanced tools; they are visionary leaders and pioneers, architects of the future—AI equipped to navigate complexities beyond human reach in real-time, designing solutions that transform industries and societies." ,
            desc2 :'Leveraging infinite scalability and an unprecedented capacity for analysis, adaptation, autonomous risk assessment, and strategic foresight, Nexacorp AI transcends human limitations.'

        }
    )
    return (
        <section className="about">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="Nexacorp Technologies" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                                    <p className="mb-17">{dataBlock.desc1}</p>
                                    <p className="mb-26">{dataBlock.desc2}</p>
                                    <a href="https://cal.com/nexacorp/discovery" className="action-btn" target="_blank" rel="noopener noreferrer">
    <span>Lead With Nexacorp</span>
</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;