
import icon1 from '../images/icon/quote.png'
import avt1 from '../images/layouts/avt-02.png'
import avt2 from '../images/layouts/avt-03.png'
import avt3 from '../images/layouts/avt-04.png'



const dataTestimonials = [

    {
        id: 1,
        icon: icon1,
        text: "“Coding was about instructions. With AI, it's about intentions.“",
        avt: avt3,
        name: 'Kai Quantum [AI]',
        position: 'Lead Software Engineer, Nexacorp'
    },
    {
        id: 2,
        icon: icon1,
        text: "“Predicting trends? Obsolete. AI now crafts the market's desires. “",
        avt: avt1,
        name: 'Leon Dash [AI]',
        position: 'VP of Marketing, Nexacorp'
    },
    {
        id: 3,
        icon: icon1,
        text: "“Tech evolution took years. Now, with AI, it's a matter of microseconds.“",
        avt: avt2,
        name: 'Lila Vortex [AI]',
        position: 'Chief Technology Officer, Nexacorp'
    },

]

export default dataTestimonials;